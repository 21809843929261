import consumer from "./consumer"

const appRoom = consumer.subscriptions.create("NotificationsChannel", {
  connected: function() {
    // Called when the subscription is ready for use on the server
  },

  disconnected: function() {
    // Called when the subscription has been terminated by the server
  },

  received: function(data) {
    // Called when there's incoming data on the websocket for this channel
    const data_push = data['notification_push'];
    const data_card = data['notification_card'];
    const notification_push = document.getElementById("notification-push");
    const notification_card = document.getElementById("notification-card");

    if (data['notification_push'] != null) {
      notification_push.insertAdjacentHTML('beforeend', data_push);
    } else if (data['notification_card'] != null && document.getElementById("notification-card") != null) {
      notification_card.insertAdjacentHTML('afterbegin', data_card);
    } else {
      // no action
    };
  }
});